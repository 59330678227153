import Layout from '@/layout'
import { getCookie } from '../assets/js/cookie';

let redirectUrl = "";

if (getCookie()) {
  redirectUrl = "/home";
} else {
  redirectUrl = "/login";
}
const routes = [
  {
    path: '/',
    redirect: redirectUrl
  },
  {
      name: 'login',
      path: '/login',
      component: () => import('@/views/login')
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/login/register')
  },
  {
    name: 'transfer',
    path: '/transfer',
    component: () => import('@/views/transfer')
  },
  {
    path: '/home',
    component: Layout,
    children: [{
      path: '/home',
      name: 'home',
      component: () => import('@/views/home')
    }]
  },
  {
    path: '/todo',
    component: Layout,
    children: [{
      path: '/todo',
      name: 'todo',
      component: () => import('@/views/todo')
    }]
  },
  {
    path: '/results',
    component: Layout,
    children: [{
      path: '/results',
      name: 'results',
      component: () => import('@/views/results')
    }]
  },
  {
    path: '/applicant',
    component: Layout,
    children: [{
      path: '/applicant',
      name: 'applicant',
      component: () => import('@/views/applicant')
    }]
  },
  {
    path: '/order-detail',
    component: Layout,
    children: [{
      path: '/order-detail',
      name: 'orderDetail',
      component: () => import('@/views/orderDetail')
    }]
  },
  {
    path: '/confirm',
    component: Layout,
    children: [{
      path: '/confirm',
      name: 'confirm',
      component: () => import('@/views/confirm')
    }]
  },
  {
    path: '/correction',
    component: Layout,
    children: [{
      path: '/correction',
      name: 'correction',
      component: () => import('@/views/correction')
    }]
  },
  // {
  //   path: '/address',
  //   component: Layout,
  //   children: [{
  //     path: '/address',
  //     name: 'address',
  //     component: () => import('@/views/address')
  //   }]
  // },
  {
    path: '/member',
    component: Layout,
    children: [{
      path: '/member',
      name: 'member',
      component: () => import('@/views/member')
    }]
  },
  {
    path: '/notice',
    component: Layout,
    children: [{
      path: '/notice',
      name: 'notice',
      component: () => import('@/views/notice')
    }]
  },
  {
    path: '/notice-detail',
    component: Layout,
    children: [{
      path: '/notice-detail',
      name: 'noticeDetail',
      component: () => import('@/views/noticeDetail')
    }]
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('@/views/auth')
  },
  {
    name: 'pdf-preview',
    path: '/pdf-preview',
    component: () => import('@/views/pdfPreview')
  },
  {
    path: '/record',
    component: Layout,
    children: [{
      path: '/record',
      name: 'record',
      component: () => import('@/views/record')
    }]
  },
  {
    path: '/company',
    mate: {keepAlive: true},
    component: Layout,
    children: [{
      path: '/company',
      name: 'company',
      component: () => import('@/views/company')
    }]
  },
  // {
  //   path: '/analysis',
  //   component: Layout,
  //   children: [{
  //     path: '/analysis',
  //     name: 'analysis',
  //     component: () => import('@/views/analysis')
  //   }]
  // },
  {
      path: '/new',
      component: Layout,
      children: [{
        path: '/new',
        name: 'new',
        component: () => import('@/views/new')
      }]
  },
  {
    path: '/setting',
    component: Layout,
    children: [{
      path: '/setting',
      name: 'setting',
      component: () => import('@/views/setting')
    }]
  },
  {
    path: '/add-applicant',
    component: Layout,
    children: [{
      path: '/add-applicant',
      name: 'addApplicant',
      component: () => import('@/views/addApplicant')
    }]
  },
  // {
  //   path: '/create-applicant',
  //   component: Layout,
  //   children: [{
  //     path: '/create-applicant',
  //     name: 'createApplicant',
  //     component: () => import('@/views/createApplicant')
  //   }]
  // },
  // {
  //   path: '/apply',
  //   component: Layout,
  //   children: [{
  //     path: '/apply',
  //     name: 'apply',
  //     component: () => import('@/views/apply')
  //   }]
  // },
  // {
  //   path: '/editor',
  //   component: Layout,
  //   children: [{
  //     path: '/editor',
  //     name: 'editor',
  //     component: () => import('@/views/editor')
  //   }]
  // },
  // {
  //   path: '/balance',
  //   component: Layout,
  //   children: [{
  //     path: '/balance',
  //     name: 'balance',
  //     component: () => import('@/views/balance')
  //   }]
  // },
  {
    path: '/package',
    component: Layout,
    children: [{
      path: '/package',
      name: 'package',
      component: () => import('@/views/package')
    }]
  },
  {
    path: '/package-list',
    component: Layout,
    children: [{
      path: '/package-list',
      name: 'packageList',
      component: () => import('@/views/packageList')
    }]
  },
  {
    path: '/correction-list',
    component: Layout,
    children: [{
      path: '/correction-list',
      name: 'correctionList',
      component: () => import('@/views/correctionList')
    }]
  },
  {
    path: '/aftersale',
    component: Layout,
    children: [{
      path: '/aftersale',
      name: 'aftersale',
      component: () => import('@/views/aftersale')
    }]
  },
  // {
  //   path: '/protect',
  //   component: Layout,
  //   children: [{
  //     path: '/protect',
  //     name: 'protect',
  //     component: () => import('@/views/protect')
  //   }]
  // },
  {
    path: '/printing-materials',
    component: Layout,
    children: [{
      path: '/printing-materials',
      name: 'printingMaterials',
      component: () => import('@/views/printingMaterials')
    }]
  },
  // {
  //   name: 'sync',
  //   path: '/sync',
  //   component: () => import('@/views/sync')
  // },
  {
    name: 'verification',
    path: '/verification',
    component: () => import('@/views/verification')
  },
  {
    name: '/test',
    path: '/test',
    component: () => import('@/views/test')
  },
  // {
  //   name: 'autoLogin',
  //   path: '/auto-login',
  //   component: () => import('@/views/autoLogin')
  // },
  {
    path: '/submit-result',
    component: Layout,
    children: [{
      path: '/submit-result',
      name: 'submitResult',
      component: () => import('@/views/submitResult')
    }]
  },
  // {
  //   path: '/development',
  //   component: Layout,
  //   children: [{
  //     path: '/development',
  //     name: 'development',
  //     component: () => import('@/views/development')
  //   }]
  // },
  {
    name: 'stop-service',
    path: '/stop-service',
    component: () => import('@/views/stopService')
  },
  {
    path: "/:catchAll(.*)", // 不识别的path自动匹配404
    name: 'NotFound',
    component: () => import('@/views/404')
  }
];

export default routes
