<script setup>
  import { ref, computed, defineProps, reactive, watchEffect } from "vue";
  import { feedBack } from '../api/index'
  import { debounce } from '../utils/index'
  import { ElMessage, ElLoading } from 'element-plus'
  import { useRouter } from "vue-router";
  import { useStore } from 'vuex'
  import { ssoInfo } from "../api/index"
  import Bus from "../js/Bus";
  const router = useRouter()
  const store = useStore()
  const formRef = ref(null);
  const formLabelWidth = '80px'
  const payType = ref(0)
  const chargeType = ref(0)
  const receiveType = ref(0)
  const invoiceType = ref(0)
  const totalPrice = ref(0)
  const limitPrice = ref(0)
  const list = reactive({
    data: []
  })
  const form = reactive({
    data: {
      title: '',
      content: '',
      phone: '',
    }
  });

  const rules = {
    content: [{ required: true, message: '意见或问题反馈不能为空', trigger: 'blur' }],
  }
  const show = computed({
    get () {
      return store.state.showFeedback
    },
    set (val) {
      store.commit('updateFeedback', val)
    }
  })

  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })
  
  const submit = () => {
    formRef.value.validate((valid) => {
      // 不通过校验
      if (!valid) return
      submitNext()
    })
  }

  const submitNext = debounce(() => {
    feedBack({...form.data}).then(res => {
      ElMessage.success(res.message)
      store.commit('updateFeedback', false)
    })
  }, 1000)

  const cancel = () => {
    store.commit('updateFeedback', false)
  }
  const beforeClose = (done) => {
    store.commit('updateFeedback', false)
    done()
  } 

  const getLabel = (key) => {
    switch (key) {
      case 'title':
        return '标题'
      case 'content':
        return '意见或问题反馈'
      case 'phone':
        return '联系方式'
      default:
        return ''
    }
  }

  watchEffect(() => {
    if (show.value) {
      form.data = {
        title: '',
        content: '',
        phone: '',
      }
    }
  })
</script>

<template>
  <div class="feed-dialog">
    <el-dialog v-model="show"
      style="height: 590px;width:530px"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <template #header>
        <div class="feed-header">
         <img src="../assets/image/xiao.png" alt="">
         <div class="feed-title">感谢您提出的宝贵意见</div>
        </div>
      </template>
        <div class="feed">
          <el-form 
            :model="form.data"
            :rules="rules"
            ref="formRef"
            label-position="top"
            label-width="0" 
            >
            <div :id="key" class="form-item" v-for="value, key in form.data" :key="key">
              <el-form-item :label="getLabel(key)" :prop="key">
                <el-input v-if="['content'].indexOf(key) !== -1" v-model="form.data[key]" type="textarea" maxlength="200"
                  show-word-limit :rows="6"/>
                <el-input v-else v-model="form.data[key]" />
              </el-form-item>
            </div>
          </el-form>
          <div class="feed-tip">若生成的材料有问题，请在标题处填写材料标题</div>
        </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="add_submit" @click="submit">提交</div>
          <div class="add_cancel" @click="cancel">取消</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style lang="less">
.feed-dialog {
  .el-dialog {
    border-radius: 8px
  }
  .el-dialog__header {
    text-align: left;
    margin-right: 0;
    border-bottom: 1px solid #D8D8D8;
  }
  .el-checkbox {
    height: 31px;
  }
  .el-form {
    margin: 0 40px;
    .el-form-item {
      margin-bottom: 24px;
    }
  }
  .el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
    .el-icon {
      margin-bottom: 0 !important;
    }
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__footer {
    border-top: 1px solid #D8D8D8;
    padding: 24px;
  }
  .feed-header {
    height: 73px;
    background-image: url("../assets/image/feedback-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    justify-content: center;
    align-items: center;
    .feed-title {
      font-weight: 500;
      color: #FFF;
      font-size: 24px;
      margin-left: 16px;
    }
  }
  .feed {
    .feed-tip {
      text-align: left;
      font-size: 16px;
      color: #EA0000;
      padding: 0 40px;
      font-weight: 400;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .add_submit {
      width: 100px;
      height: 31px;
      line-height: 31px;
      background: #6196FF;
      border-radius: 50px;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      cursor: pointer;
    }
    .add_cancel {
      width: 100px;
      height: 29px;
      line-height: 29px;
      border-radius: 50px 50px 50px 50px;
      opacity: 1;
      border: 1px solid #6196FF;
      font-size: 16px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      color: #6196FF;
      margin-left: 16px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>