export function hidePhone (tel) {
  if (!tel) return
  var reg = /^(\d{3})\d{4}(\d{4})$/
  return tel.replace(reg, '$1****$2')
}

export function hideCard (card) {
  if (!card) return
  var reg = /^(.{4})(?:\d+)(.{4})$/
  return card.replace(reg, '$1******$2')
}

export function hideEmail (email) {
  if (String(email).indexOf('@') > 0) {
    let newEmail
    let str = email.split('@')
    let _s = ''

    if (str[0].length > 2) {
      _s = str[0].substr(0, 1)
      _s += '****'
      _s += str[0].substr(-1)
      newEmail = _s + '@' + str[1]
    } else {
      newEmail = str[0] + '@' + str[1]
    }
    return newEmail
  } else {
    return email
  }
}

/**
 * 普通防抖函数  一个需要频繁触发的函数，在规定时间内，只让最后一次生效，前面的不生效
 * @param fn要被防抖的函数
 * @param delay规定的时间
 */
export function debounce(fn, delay) {
  //记录上一次的延时器
  let timer = null;
  return function(...args) {
    //记录指向被调用的this
    let _this = this;
    //清除上一次的延时器
    clearTimeout(timer);
    //重新设置新的延时器
    timer = setTimeout(function() {
      //修正this指向问题
      fn.apply(_this, args); // 注意apply的第二个参数是数组
      timer = null;  // clearTimeout(timer) 只会将延时器取消，并不会置空，在闭包的情况下不会在内存里回收，所以需要在定时器执行完之后，将timer置空来使timer被垃圾回收机制回收，防止内存泄漏。 闭包内的null变量会被回收。
    }, delay);
  }
}

export function throttle(fn, delay) {
  let timer = null
  let lastTime = 0
  return function(...args) {
    let _this = this
    const currentTime = Date.now()
    //如果当前时间与上一次执行函数的时间差大于等于延时时间，执行函数
    if (currentTime - lastTime >= delay) {
      fn.apply(_this, args)
      lastTime = currentTime
    }
    //如果定时器不存在 设置定时器，在一定时间后重置上一次执行函数的时间为当前时间
    if (!timer) {
      timer = setTimeout(function(){
        timer = null
        lastTime - Date.now()
        fn.apply(_this, args)
      }, delay)
    }
  }
}

export function flatRoleList (menus) {
  let parents = menus.filter(item => item.parentId === 0)
  parents.forEach(function (parent) {
    let children = []
    menus.forEach((item) => {
      if (item.parentId === parent.id) {
        children.push(item)
      }
    })
    // if (parent.id === 10) {
    //   children.push({
    //     createTime: '2020-02-07T16:30:13',
    //     hidden: 0,
    //     icon: 'company_file',
    //     id: 21,
    //     level: 1,
    //     name: 'file_statistics',
    //     parentId: 10,
    //     path: '/admin/file/statistics',
    //     sort: 0,
    //     title: '履约统计'
    //   })
    // }
    parent.children = children
  })
  let adminMenus = parents.filter(item => item.parentId === 0)
  return adminMenus
}

export function copyVal (val) {
  let text = document.createElement('textarea')
  text.value = val
  text.style.opacity = '0'
  document.body.appendChild(text)
  text.select()
  document.execCommand('copy')
}

export function blobToDataURL (blob, cb) {
  let reader = new FileReader()
  reader.onload = function (evt) {
    let base64 = evt.target.result
    cb(base64)
  }
  reader.readAsDataURL(blob)
}

export function dataURLtoBlob (dataurl) {
  var arr = dataurl.split(',')
  var mime = arr[0].match(/:(.*?);/)[1]
  var bstr = atob(arr[1])
  var n = bstr.length
  var u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new Blob([u8arr], { type: mime })
}

export function blobToFile (theBlob, fileName) {
  return new window.File([theBlob], fileName)
}

export function removeBathSig (arr) {
  let array = []
  for (let i = 0; i < arr.length; i++) {
    let isChage = true
    for (let z = 0; z < array.length; z++) {
      if (arr[i] !== null && array[z] !== null && arr[i].signType === 6) {
        if (arr[i]['typeId'] === array[z]['typeId'] && arr[i]['uuid'] === array[z]['uuid'] && arr[i]['w'] === array[z]['w'] &&
          arr[i]['h'] === array[z]['h'] && arr[i]['x'] === array[z]['x'] && arr[i]['y'] === array[z]['y'] && arr[i]['p'].toString() === array[z]['p'].toString()) {
          isChage = false
        }
      }
    }
    if (isChage) {
      array.push(arr[i])
    }
  }
  return array
}

export function removeSign (arr) {
  let array = []
  for (let i = 0; i < arr.length; i++) {
    let isChage = true
    for (let z = 0; z < array.length; z++) {
      if (arr[i]['typeId'] === array[z]['typeId']) {
        isChage = false
      }
    }
    if (isChage) {
      array.push(arr[i])
    }
  }
  return array
}

export function removeRepeatUUID (arr) {
  // 缓存用于记录
  const newArr = []
  for (const t of arr) {
    // 检查缓存中是否已经存在
    if (
      t && t.signType === 6 && t.uuid &&
      newArr.find((c) => c && c.uuid === t.uuid)
    ) {
      // 已经存在说明以前记录过，现在这个就是多余的，直接忽略
      continue
    }
    // 不存在就说明以前没遇到过，把它记录下来
    newArr.push(t)
  }
  // 记录结果就是过滤后的结果
  return newArr
}
