<script setup>
/* eslint-disable */
  import { ElMessage } from 'element-plus'
  import { useStore } from 'vuex'
  import { useRouter } from "vue-router";
  import { ref, reactive, computed, watchEffect } from 'vue';
  import Bus from "../js/Bus";
  import axios from 'axios'

  const show = computed({
    get () {
      return store.state.showLoginAuth
    },
    set (val) {
      store.commit('updateLoginAuth', val)
    }
  })

  const router = useRouter()
  const store = useStore()
  const loginFormRef = ref(null);
  const btnLoading = ref(false)
  const loginForm = reactive({
    username: '',
    password: '',
    accountType: 1 //0个人 1 企业
    // agree: false
  });

  const formRules = {
    username: [
      { required: true, message: '用户名不能为空', trigger: 'blur' },
    ],
    password: [
      { required: true, message: '密码不能为空', trigger: 'blur' },
    ]
  }
  const beforeClose = (done) => {
    store.commit('updateLoginAuth', false)
    done()
  } 

  const cancel = () => {
    store.commit('updateLoginAuth', false)
  }

  const subType = ref(1)

  const submit = () => {
    if (!checked.value) {
      ElMessage.warning('请阅读并同意授权须知')
      return
    }
    store.commit('updateLoginAuth', false)
    store.commit('updateCaptcha', true)
  }

  const checked = ref(false)

  watchEffect(() => {
    if (show.value) {
      checked.value = false
    }
  })
</script>

<template>
  <div class="authorization">
    <el-dialog v-model="show"
      style="width: 339px"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div class="auth-body">
        <div class="title-info">软件登记服务平台将申请使用</div>
        <div class="middle-info">您在【中国版权保护中心】网站的账号信息</div>
        <div class="tips-info">为什么要使用这些信息
          <el-tooltip
            class="box-item"
            effect="dark"
            content="暂无"
            placement="bottom"
          >
            <el-icon style="margin-left:11px;" color="#0073FF" size="16"><QuestionFilled /></el-icon>
          </el-tooltip>
        </div>
        <div class="btns">
          <el-button type="primary" :loading="btnLoading" :disabled="btnLoading" class="subBtn" @click="submit">授权</el-button>
          <el-button class="cancelBtn" @click="cancel">取消</el-button>
        </div>
        <div class="check-item">
          <el-checkbox v-model="checked" label="阅读并同意" size="large" />
          <span class="sqxz">《授权须知》</span>
        </div>
      </div>
      <template #header>
        <div class="dialog-header">
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.authorization {
  :deep(.el-dialog) {
    .el-dialog__header {
      padding: 0;
      margin: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      padding: 0;
    }
    .auth-body {
      padding: 24px;
      text-align: left;
      .title-info {
        font-weight: 400;
        font-size: 14px;
        color: #767676;
      }
      .middle-info {
        font-weight: 400;
        font-size: 14px;
        color: #1A1A1A;
        margin: 16px 0;
      }
      .tips-info {
        font-weight: 400;
        font-size: 14px;
        color: #0073FF;
        display: flex;
        align-items: center;
      }
      .btns {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        .subBtn {
          width: 182px;
          height: 52px;
          background: #0073FF;
          border-radius: 0px 0px 0px 0px;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          margin-right: 4px;
        }
        .cancelBtn {
          width: 105px;
          height: 52px;
          background: #FFFFFF;
          border-radius: 0px 0px 0px 0px;
          border: 1px solid #D8D8D8;
          font-weight: 400;
          font-size: 14px;
          color: #1A1A1A;
        }
      }
      .check-item {
        margin-top: 24px; 
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        label {
          height: 20px;
        }
        span {
          font-size: 14px;
        }
        .sqxz {
          color: #0073FF;
          cursor: pointer;
          height: 20px;
        }
      }
    }
  }
}

</style>