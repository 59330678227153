import request from '../../utils/request'

export function smsGetCode (params) {
  return request({
    url: '/sms/getCode',
    method: 'get',
    params: params
  })
}

export function authApply (data) {
  return request({
    url: '/umsCompany/auth/apply',
    method: 'post',
    data
  })
}

export function companyVerify (data) {
  return request({
    url: '/umsCompany/auth/companyVerify',
    method: 'post',
    data
  })
}

export function queryCompany (data) {
  return request({
    url: '/gs-enterprise-admin/umsCompany/auth/query',
    method: 'post',
    data
  })
}

export function getPayed () {
  return request({
    url: '/gs-enterprise-admin/umsCompany/auth/payed',
    method: 'get'
  })
}

export function vaildUsername (params) {
  return request({
    url: '/sso/username',
    method: 'get',
    params
  })
}

export function ssoRegister (data) {
  return request({
    url: '/sso/register',
    method: 'post',
    data
  })
}

export function authPersonApply (data) {
  return request({
    url: '/umsCompany/auth/person/apply',
    method: 'post',
    data
  })
}

export function inviteCodeAuth (params) {
  return request({
    url: '/inviteCode/isGaoshan',
    method: 'get',
    params
  })
}
