<script setup>
import { useStore } from 'vuex'
import { ref } from 'vue';
import { useRoute } from 'vue-router';
  const store = useStore()
  const route = useRoute()
  const showLi = ref(false)
</script>

<template>
  <!-- <div class="help-center" @click="submit">
    <div class="help-inside">
      <div>帮助</div>
      <div>中心</div>
    </div>
  </div> -->
  <div class="help-center" @mouseleave="showLi = false" @mouseenter="showLi = true">
        <div class="help-circle"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2" target="_blank">帮助<br>中心</a></div>
        <ul class="help-list" :class="{'show': showLi && (route.path === '/home' || route.path === '/new' || route.path === '/confirm')}">
          <li v-if="route.path === '/home' || route.path === '/new'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/ko1wbuho5tppzas6" target="_blank" class="helplink">创建材料</a></li>
          <li v-if="route.path === '/new'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/zlvrpkxseoksgmvg" target="_blank" class="helplink">代理提交</a></li>
          <li v-if="route.path === '/new'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/bcugcl8taqekcvch" target="_blank" class="helplink">合作开发</a></li>
          <li v-if="route.path === '/home'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/ntposip7nyoa3l61" target="_blank" class="helplink">批量创建</a></li>
          <li v-if="route.path === '/home'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/ho83e8kwnto2ohh4" target="_blank" class="helplink">申请售后</a></li>
          <li v-if="route.path === '/confirm'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/lokxi0d9gaiukhfk" target="_blank" class="helplink">在线提交</a></li>
          <li v-if="route.path === '/confirm'"><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2/heg6ox71q80t17dl" target="_blank" class="helplink">加急通道</a></li>
          <li><a href="https://rzdengji.yuque.com/org-wiki-rzdengji-fpoa3p/bg2bv2" target="_blank" class="helplink">更多帮助</a></li>
        </ul>
    </div>
</template>

<style scoped lang="less">
  // .help-center {
  //   position: fixed;
  //   height: 90px;
  //   width: 90px;
  //   right: 15px;
  //   bottom: 50px;
  //   background: #E6E6E6;
  //   border-radius: 50%;
  //   z-index: 999;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   cursor: pointer;
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
  //   .help-inside {
  //     border-radius: 50%;
  //     color: #fff;
  //     background: var(--themColor);
  //     font-size: 18px;
  //     font-weight: bold;
  //     height: 80px;
  //     width: 80px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     flex-direction: column;
  //     div {
  //       line-height: 22px;
  //     }
  //   }
  // }
  // /* 当鼠标移入或者触摸到按钮时，显示按钮并修改其属性 */
  // .help-center:hover,
  // .help-center:focus {
  //     opacity: 1; /* 显示按钮 */
  //     transform: scale(1.1); /* 放大按钮 */
  //     box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* 添加阴影效果 */
  // }
  .help-center {
    position: fixed;
    bottom: 50px;
    right: 40px;
    z-index: 999;
}

.help-circle {
    width: 60px;
    height: 60px;
    border: 5px solid #d4d4d4;
    border-radius: 50%;
    background-color: #6196ff;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: pulse 6s infinite;
    font-size: 14px;
    a {
      color: inherit;
      text-decoration: inherit;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.help-list {
    position: absolute;
    bottom: 60px;
    right: 0;
    list-style: none;
    max-height: 0;
    width: 100px;
    overflow: hidden;
    transition: max-height 2.5s ease, opacity 1s ease;
    opacity: 0;
    text-align: center;
}

.help-list.show {
    max-height: 800px;
    opacity: 1;
}

.help-list li {
    padding: 8px 10px;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    color: #fff;
    border-radius: 50px;
    margin: 16px 0;
}

.help-list li:hover {
    background-color: #6196ff;
}

.helplink {
    color: white;
    text-decoration: none;
}
</style>