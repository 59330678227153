<template>
  <div class="notice">
    <svg t="1711001135103" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14743" width="200" height="200"><path d="M305.936725 299.8272L588.833792 67.584a43.485867 43.485867 0 0 1 62.395733 7.304533 45.738667 45.738667 0 0 1 9.898667 28.2624v865.006934a44.373333 44.373333 0 0 1-44.6464 45.056 43.4176 43.4176 0 0 1-27.648-10.24l-290.747733-237.294934H78.267392a45.056 45.056 0 0 1-44.578133-45.738666V345.634133c0-25.3952 19.797333-45.738667 44.6464-45.738666h227.601066zM874.529792 815.104a43.144533 43.144533 0 0 1-62.395733-1.501867 46.148267 46.148267 0 0 1 0.682666-63.146666 308.974933 308.974933 0 0 0 12.0832-421.614934 45.6704 45.6704 0 0 1 2.116267-63.829333 43.144533 43.144533 0 0 1 62.395733 2.184533l0.682667 0.682667A396.629333 396.629333 0 0 1 990.105259 533.435733c0 106.018133-41.1648 207.598933-115.575467 281.6z" fill="#6196FF" p-id="14744"></path></svg>  
    <!-- <div>xxxx</div> -->
    <div @click="msgClick" ref="back" class="back">
      <div ref="text" :style="{fontSize:data.size?data.size:'16px',color:data.color?data.color:'#000'}" class="text">{{ data.text?data.text:'' }}</div>
    </div>
    <!-- <transition name="fade">
      <div class="noticebar" :style="{backgroundColor:data.backround}">
        <img v-if="data.icon" :style="{height:data.iconSize?data.iconSize:'16px',width:data.iconSize?data.iconSize:'16px'}" :src=data.icon alt="">
        <div @click="msgClick" ref="back" class="back">
          <div ref="text" :style="{fontSize:data.size?data.size:'16px',color:data.color?data.color:'#000'}" class="text">{{ data.text?data.text:'' }}</div>
        </div>
      </div>
    </transition> -->
    <div @click="more" v-if="showMore" class="notice-more">更多></div>
  </div>
</template>
<script>
export default {
    props: {
        options: {
            type: Object,
            default() {
                return {
                    text: ''
                };
            }
        },
        showMore: {
          require: false,
          type: Boolean,
          default: true
        },
        infoClick: {
          require: false,
          type: Function,
          default: () => {
            return () => {}
          }
        },
        moreClick: {
          require: false,
          type: Function,
          default: () => {
            return () => {}
          }
        }
    },
    data() {
        return {
            speed: this.options.speed, // 速度（单位px/s）
            backWidth: '', // 父级宽度
            backHeight: '', // 父级高度
            wordLength: '', // 文本长度
            state: 1,
            firstAnimationTime: '', // 状态一动画效果
            secondAnimationTime: '', // 状态二动画效果
            data: this.options
        };
    },
    methods: {
        msgClick(){
          this.infoClick()
        },
        more() {
          this.moreClick()
        },
        // 获取数据
        getData() {
            let style = document.styleSheets[0];
            let text = this.$refs.text;
            let back = this.$refs.back;
            this.backWidth = back.offsetWidth;
            this.backHeight = back.offsetHeight;
            text.style.lineHeight = this.backHeight + 'px';
            this.wordLength = text.offsetWidth;
            this.ComputationTime(); // 计算时间
            style.insertRule(
                `@keyframes firstAnimation {0%   {left:0px;}100%  {left:-${this.wordLength}px;}}`
            );
            style.insertRule(
                `@keyframes secondAnimation {0%   {left:${this.backWidth}px;}100%  {left:-${this.wordLength}px;}}`
            );
            setTimeout(res => {
                // this.changeState();
            }, this.data.delay);
        },
        // 用速度计算时间（想要保持速度一样，2种状态时间不同需算出）
        ComputationTime() {
            this.firstAnimationTime = this.wordLength / this.speed;
            this.secondAnimationTime =
                (this.wordLength + this.backWidth) / this.speed;
        },
        // 根据状态切换动画
        changeState() {
            let text = this.$refs.text;
            if (this.state == 1) {
                text.style.animation = `firstAnimation ${this.firstAnimationTime}s linear`;
                this.state = 2;
            } else {
                text.style.animation = `secondAnimation ${this.secondAnimationTime}s linear infinite`;
            }
        },
        Listener() {
            let text = this.$refs.text;
            text.addEventListener(
                'animationend',
                res => {
                    this.changeState();
                },
                false
            );
        }
    },
    mounted() {
        // this.Listener();
        // this.getData();
        // setTimeout(res => {
        // }, 100);
    }
};
</script>
<style lang="less" scoped>
.notice {
    max-width: 300px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 10px;
      width: 17px;
      height: 17px;
    }
    
    .noticebar {
        display: flex;
        align-items: center;
        height: 100%;
        // width: 100%;
        flex: 1;
        background-color: transparent;
        .icon {
            img {
                height: 100%;
                width: 100%;
            }
        }
        .back {
            overflow: hidden;
            white-space: nowrap;
            margin: 0 auto;
            height: 100%;
            width: 100%;
            position: relative;
            cursor: pointer;
            .text {
                width: 100%;
                position: absolute;
                display: inline-block;
                // -webkit-touch-callout: none; /* iOS Safari */
                // -webkit-user-select: none;   /* Chrome/Safari/Opera */
                // -moz-user-select: none;      /* Firefox */
                // -ms-user-select: none;       /* Internet Explorer/Edge */
                // user-select: none; 
                left: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
    .back {
      max-width: 200px;
      overflow: hidden;
      .text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
      }
    }

    .notice-more {
      margin: 0 19px 0 8px;
      color: #6196FF;
      font-size: 12px;
      width: 39px;
      display: inline-block;
      cursor: pointer;
    }
  }
</style>
