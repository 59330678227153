import request from '../utils/request'

export function afterPage(params) {
  return request({
    url: '/after/sale/page',
    method: 'GET',
    params: params
  })
}

export function afterApply(data) {
  return request({
    url: '/after/sale/apply',
    method: 'POST',
    data
  })
}

export function correctionList(params) {
  return request({
    url: '/correction/reason/list',
    method: 'GET',
    params: params
  })
}

export function setSubmitWay(params) {
  return request({
    url: '/after/sale/setSubmitWay',
    method: 'GET',
    params: params
  })
}
