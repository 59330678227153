<template>
  <router-view/>
  <!-- <router-view>
    <template #default="{Component, route}">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="route.fullPath"></component>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" :key="route.fullPath"></component>
    </template>
  </router-view> -->
  <!-- <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :is="Component" :key="route.name"></component>
    </keep-alive>
  </router-view> -->
  <div id="captcha"></div>
  <SoftDialog></SoftDialog>
  <HelpCenter v-if="route.path !== '/test' && route.path !== '/login' && route.path !== '/register' && route.path !== '/404' && route.path !== '/printing-materials' && route.path !== '/stop-service'"></HelpCenter>
  <!-- <Question v-if="route.path !== '/login' && route.path !== '/register' && route.path !== '/404' && route.path !== '/printing-materials' && route.path !== '/stop-service'"></Question> -->
  <FeedBack></FeedBack>
  <CaptchaLogin></CaptchaLogin>
  <UpdateApplicant :name="name" @submit="submit"></UpdateApplicant>
  <Auth></Auth>
</template>

<script setup>
/* eslint-disable */
import SoftDialog from './components/SoftDialog.vue'
import Question from './components/Question.vue'
import HelpCenter from './components/HelpCenter.vue'
import FeedBack from './components/FeedBack.vue'
import CaptchaLogin from './components/CaptchaLogin.vue'
import UpdateApplicant from './components/UpdateApplicant.vue'
import Auth from './components/Auth.vue'
import { saveCopyrightAccountToken } from './api/index'
import { computed, ref, onMounted, reactive } from "vue";
import axios from 'axios'
import Bus from "./js/Bus";
import crypto from 'crypto'
import { ElMessage, ElLoading } from 'element-plus'
import { useStore } from 'vuex'
import { useRoute, useRouter } from "vue-router";
const router = useRouter()
const route = useRoute()
const store = useStore()
const username = ref('')
const password = ref('')
const accountType = ref(0)
const subType = ref(0)
const captchaIns = ref()

const writeId = computed({
  get () {
    return store.state.writeId
  },
  set (val) {
    store.commit('updateWriteId', val)
  }
})

const isAuth = computed({
  get () {
    return store.state.isAuth
  },
  set (val) {
    store.commit('updateIsAuth', val)
  }
})

const showCaptcha = computed({
    get () {
      return store.state.showCaptcha
    },
    set (val) {
      store.commit('updateCaptcha', val)
    }
  })

const captchaSubmit = (e) => {
  console.log(e);
  username.value = e.username
  password.value = e.password
  accountType.value = e.accountType
  subType.value = e.subType
  // captchaIns.value && captchaIns.value.verify()
}

const isSuccess = ref(false)

const initNECaptcha = () => {
  // initNECaptchaWithFallback({
  //   element: '#captcha',
  //   captchaId: 'a7bef55d9fed4e36a1004f2f8926a617', // a7bef55d9fed4e36a1004f2f8926a617
  //   width: '320px',
  //   mode: 'popup',
  //   apiVersion: 2,
  //   popupStyles: {
  //     position: 'fixed',
  //     top: '20%'
  //   },
  //   onClose: function() {
  //     captchaIns.value.refresh()
  //     if (!isSuccess.value) {   
  //       if (route.path === '/home') {
  //         Bus.$emit('copyrightLoginDone', {
  //           type: 0,
  //           isCancel: true
  //         })
  //       }
  //       isSuccess.value = false
  //     }
  //   },
  //   onVerify: function (err, data) {
  //     if (err) return // 当验证失败时，内部会自动refresh方法，无需手动再调用一次
  //     // 若成功拿到 validate，可向后端发送请求
  //     isSuccess.value = true
  //     ajax2Server(data.validate)
  //   }
  // }, function onload(instance) {
  //   captchaIns.value = instance
  // }, function onerror(err) {
  //   console.warn(err)
  // })
}

const ajax2Server = (validate) => {
  if (validate) {
    // showInfoRef.value.innerHTML = `
    // 已成功拿到参数：<br/>
    // {<br/>
    //   account: ${formState.account},<br/>
    //   password: ${formState.password},<br/>
    //   validate: ${validate}<br/>
    // }<br/>
    // 可以向后端发起请求了<br/>`
    let params = {
      accountType: accountType.value, //0 个人 1企业
      // subType: "SUB" //员工
      loginName: username.value,
      password: password.value,
      ticket: validate,
      veriftyType: "1",
    }
    if (subType.value === 0) {
      params.subType = 'SUB'
    }
    const loading = ElLoading.service({
      lock: true,
      text: '登录中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    axios.post('https://gateway.ccopyright.com.cn/userServer/login/login/accountLogin', params).then(function (res) {
      console.log(res);
      console.log(res.data);
      if (res.data.returnCode === 'FAILED') {
        console.log(this);
        ElMessage.error(res.data.message)
        loading.close()
        if (!showCaptcha.value) {
          store.commit('updateCaptcha', true)
        }
      } else if (res.data.returnCode === 'SUCCESS') {
        saveData.value = res.data.data
        saveToken(res.data.data, loading)
      }
    })
    .catch(function (error) {
      console.log(error);
      loading.close()
    })
    .finally(function () {
      // always executed
      console.log();
    });
  }
}

const name = ref('')
const applicantId = ref('')
const saveData = ref('')

const saveToken = (data, loading) => {
  let params = JSON.parse(JSON.stringify(data))
  if (!isAuth) {
    params.writeId = writeId.value
  }
  params.authorizationKey = data.authorization_key
  params.authorizationToken = data.authorization_token
  if (subType.value === 0) {
    params.subType = 'SUB'
  } else {
    params.subType = ''
  }
  params.loginName = username.value
  params.password = password.value
  params.accountType = accountType.value
  saveCopyrightAccountToken(params).then(res => {
    store.commit('updateCaptcha', false)
    console.log('route.path', route.path);
    if (res.code === 415) {
      name.value = res.data.name
      applicantId.value = res.data.id
      store.commit('updateShowApplicant', true)
    } else if (res.code === 416) {
      ElMessage({
        showClose: true,
        message: res.message,
        type: 'error',
        duration: 5000
      })
    } else if (res.code === 417) {
      ElMessage({
        showClose: true,
        message: res.message,
        type: 'error',
        duration: 5000
      })
    } else {
      if (route.path === '/confirm') {
        Bus.$emit('copyrightLoginDone', {
          type: 1
        })
      } else if (route.path === '/printing-materials') {
        Bus.$emit('copyrightLoginDone', {
          type: 2
        })
      } else if (route.path === '/add-applicant') {
        console.log('data.account.id', data.account.id);
        Bus.$emit('copyrightLoginDone', {
          type: 3,
          id: data.account.id
        })
      } else if (route.path === '/applicant') {
        Bus.$emit('copyrightLoginDone', {
          type: 4
        })
      } else if (route.path === '/correction') {
        Bus.$emit('copyrightLoginDone', {
          type: 5
        })
      } else if (route.path === '/new') {
        console.log('data.account.id', data.account.id);
        Bus.$emit('copyrightLoginDone', {
          type: 6,
          id: data.account.id
        })
      } else {
        Bus.$emit('copyrightLoginDone', {
          type: 0,
          isCancel: false
        })
      }
    }
    loading.close()
  }).catch(()=> {
    loading.close()
  })
}

const submit = () => {
  console.log('app submit');
  writeAnewFile({
    applicant: name.value,
    ownerId: applicantId.value,
    writeId: writeId.value
  }).then(res => {
    console.log(res);
    store.commit('updateShowApplicant', false)
    store.commit('updateCaptcha', false)
    const loading = ElLoading.service({
      lock: true,
      text: '登录中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    saveToken(saveData.value, loading)
  })
}

onMounted(() => {
  Bus.$off('showVerify')
  Bus.$on('showVerify', (e) => {
    isSuccess.value = false
    captchaSubmit(e)
  })
  initNECaptcha()
})
</script>

<style>

.el-cascader__dropdown {
  min-width: 452px;
}

.el-cascader-menu {
  width: 100%;
}

:root {
  --themColor: #6196FF; /*主题色*/
}

#app {
  font-family:  -apple-system, BlinkMacSystemFont, "PingFang SC", "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0;
  padding: 0;
}
.el-overlay {
  z-index: 11000 !important;
}

.el-popper {
  z-index: 11001 !important;
}

.yidun_popup.yidun_popup--light {
  z-index: 11002 !important;
}

.el-loading-mask {
  z-index: 11003 !important;
}

.el-dropdown__popper {
  z-index: 11000 !important;
}

.driver-overlay {
  z-index: 20000 !important;
}

.el-message {
  z-index: 11002 !important;
}
.el-dialog {
  margin: 18vh auto 50px !important
}

/* 隐藏浏览器默认的滚动条 */
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1; /* 滑块背景颜色 */
  border-radius: 6px; /* 滑块的圆角 */
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent; /* 轨道背景颜色 */
}

/* 鼠标悬停在滚动条上的样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #EEE;
  cursor: grab;
}

.tab-btn-item + .tab-btn-item {
  margin-left: 16px;
}

.tab-btn-item {
  word-break: keep-all;
  /* line-height: 24px; */
  height: 24px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #FAFAFA;
  border: 1px solid #c0c6cc;
  padding: 0 16px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-btn-item:hover {
  color: #6196FF;
  border: 1px solid #6196FF;
}
.tab-btn-active {
  color: #6196FF;
  border: 1px solid #6196FF;
}
/* .tab-btn-active:hover {
  color: #6196FF;
  border: 1px solid #6196FF;
} */

.btn-link + .btn-link {
  margin-left: 12px;
}

.btn-link {
  word-break: keep-all;
  line-height: 32px;
  height: 32px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #6196FF;
  padding: 0;
  cursor: pointer;
}

.btn-item + .btn-item {
  margin-left: 8px;
}

.success-btn {
  color:#00B578;
}

.wait-btn {
  color:#FFC300;
}

.btn-dis {
  cursor: no-drop !important;
  opacity: 0.6;
}

.btn-item {
  word-break: keep-all;
  /* line-height: 32px; */
  height: 32px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: #FAFAFA;
  border: 1px solid #c0c6cc;
  padding: 0 16px;
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-item_1 {
  color: #6196FF !important;
  border: 1px solid #6196FF !important;
}

.btn-item_1:hover {
  color: #4f89fd !important;
  border: 1px solid #4f89fd !important;
}


.btn-item .el-button:focus {
  outline: none;
  box-shadow: none;
}

.el-button {
  border-radius: 0;
  box-sizing: border-box;
}

.el-button:hover {
  color: inherit;
  border-color: inherit;
  background-color: inherit;
}

/* .btn-item .el-button:hover {
  outline: none;
  box-shadow: none;
} */

.btn-item:hover {
  border: 1px solid #737373;
}
.btn-active {
  color: #FFFFFF;
  background: #6196FF;
}
.btn-active:hover {
  color: #FFF;
  background: #4f89fd;
  border: 1px solid #4f89fd;
}

.btn-active-warning {
  color: #FFFFFF;
  background: #ffa305;
  border: 1px solid #ffa305;
}
.btn-active-warning:hover {
  color: #FFF;
  background: #efa305;
  border: 1px solid #efa305;
}

.btn-active-link {
  color: #6196FF;
  border: 1px solid #6196FF;
}

.btn-active-link:hover {
  color: #4f89fd;
  border: 1px solid #4f89fd;
}

.page-title {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: #000000;
  padding-bottom: 16px;
  text-align: left;
}

.el-table .cell {
  line-height: normal;
  font-weight: 400;
}

body {
  margin: 0;
  padding: 0;
  background: #FFFFFF
  /* background: #F2F2F2; */
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.text-center {
  align-items: center;
}
.text-start {
  align-items: flex-start !important;
}
.text-end {
  align-items: flex-end !important;
}
.flex-cloum {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-warp {
  display: flex;
  flex-wrap: wrap;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
