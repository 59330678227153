import {createRouter, createWebHashHistory} from 'vue-router'
import routes from './routes'

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})


// 全局前置守卫:每次路由切换时，都会调用beforeEach
// to：要跳转到哪 from：当前导航要离开的路由信息 next是否继续跳转路由 如果要就调用next()
router.beforeEach((to, from, next) => {
  // 获取到前面打过标签的元素，将其scrollTop属性设置为0
  document.documentElement.scrollTop = 0
  // 滚动条可能出现在HTML元素上而不是body上，确保兼容性，同时将HTML和body中scrollTop属性设置为0
  document.body.scrollTop = 0
  // 调用继续路由跳转
  next()
})

export default router
