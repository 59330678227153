const moment = require('moment')
export function	formatDate(value, format) {
	if (!value) {
		return '';
	}
  return moment(value).format(format)
}

export function dateToTimestamp(date) {
  return moment(date).valueOf()
}


export function calculateBirthdayAfterYears(date, N) {
	console.log('calculateBirthdayAfterYears', date);
	
	const birthday = new Date(date); // 示例生日
	const birthdayCopy = new Date(birthday);
	birthdayCopy.setFullYear(birthday.getFullYear() + N);
	return birthdayCopy;
}

export function getDaysBetweenDates(date) {
  // 将日期字符串转换为Date对象
  var d1 = new Date(moment(date).format('YYYY-MM-DD'));
  var d2 = new Date(Date.now());
	console.log(d2.getTime());
	// 计算两个日期的时间差（毫秒）
	var timeDiff = Math.abs(d2.getTime() - d1.getTime());
	// 计算天数
	var days = Math.round(timeDiff / (1000 * 3600 * 24));
	if (d2.getTime() > d1.getTime()) {	
		return days;
	} else {
		return days * -1
	}
}