import request from '../utils/request'

export function bannerPage(params) {
  return request({
    url: '/banner/page',
    method: 'GET',
    params: params
  })
}

export function bannerList(params) {
  return request({
    url: '/banner/list',
    method: 'GET',
    params: params
  })
}

export function banner(params) {
  return request({
    url: '/banner',
    method: 'GET',
    params: params
  })
}


